@import 'mixins.scss';
@import 'fonts.scss';

* {
    user-select: none;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    min-width: 320px;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'DBHelvethaicaX-63MedExt', Tahoma;
    font-size: 24px;
    color: #151616;
    background: #000;
    display: block;
    position: relative;
    overflow-x: hidden;
    @media only screen and (max-width: 1024px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 700px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 600px) {
        font-size: 10px;
    }
}

body::-webkit-scrollbar {
    width: 1vmin;
} 

body::-webkit-scrollbar-thumb {
    background: #07f468; 
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
    background: #dea037; 
}

a, a:hover, a:active, a:focus{
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

img {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

ul, li {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style-type: none;
}

p {
    margin: 0;
}

.tooltip{
    font-size: 1.8vw;
}