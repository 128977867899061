@mixin font-face($style-name, $file, $category:"") {
    $filepath: "../static/fonts/" + $file;
    @font-face {
        font-family: "#{$style-name}";
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'), url($filepath + ".woff") format('woff'), url($filepath + ".ttf")  format('truetype'), url($filepath + ".svg#" + $style-name + "") format('svg');
    }
    %#{$style-name} {
        font: {
            @if $category != "" {
                family: "#{$style-name}", #{$category};
            }
            @else {
                family: "#{$style-name}";
                weight: normal;
            }
        }
    }
}

@include font-face('PSLKittithadaPro', 'PSLKittithadaPro', 'serif');
@include font-face('Flama-BlackItalic', 'Flama-BlackItalic', 'serif');
@include font-face('DBHelvethaicaX-63MedExt', 'DBHelvethaicaX-63MedExt', 'serif');
@include font-face('DBHelvethaicaX-64MedExtIt', 'DBHelvethaicaX-64MedExtIt', 'serif');
@include font-face('DBHelvethaicaX-73BdExt', 'DBHelvethaicaX-73BdExt', 'serif');
@include font-face('DBHelvethaicaX-74BdExtIt', 'DBHelvethaicaX-74BdExtIt', 'serif');